.awssld__content div {
  z-index: 9;
}

.awssld__content div h3 {
  color: #ffffff;
  font-size: 300%;
  text-shadow: none;
  line-height: 1;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: uppercase;
  font-weight: 700;
  text-shadow: 0 2px 4.8px rgba(0, 0, 0, 0.3);
  font-family: "Agency FB";
}

.awssld__content div h1 {
  color: #ffffff;
  font-size: 600%;
  text-shadow: none;
  line-height: 1;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: uppercase;
  font-weight: 700;
  text-shadow: 0 2px 4.8px rgba(0, 0, 0, 0.3);
  margin: 0 0 32px 0;
  font-family: "Agency FB";
}

.awssld__content div p {
  color: #ffffff;
  font-size: 100%;
  text-shadow: none;
  line-height: 1.5;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-shadow: 0 2px 4.8px rgba(0, 0, 0, 0.3);
  font-family: "Roboto", "Arial";
  padding: 0px 200px;
}

.awssld__content div .button-container {
  width: 100%;
  text-align: center;
  padding: 30px 0 0 0;
}

.awssld__content div .button-container a {
  background: #e20074;
  font-family: "Roboto", "Arial";
  color: #ffffff;
  font-size: 100%;
  text-shadow: none;
  line-height: 1.5;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 0;
  box-shadow: 0 2px 4.8px 0.3px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-out;
  padding: 19px 50px 19px 50px;
}

.awssld__content div .button-container a:hover {
  background: #ba0060;
  color: #ffffff;
}

.awssld__bullets {
  bottom: 40px;
  z-index: 8;
}

.awssld__bullets button {
  background: #000000;
}

.awssld__bullets .awssld__bullets--active {
  background: #6984ef;
}

.sl-social {
  z-index: 10;
}

@media (max-width: 1200px) {
  .awssld__container {
    padding-bottom: 768px;
  }

  .sl-social {
    top: 40%;
  }
}

@media (max-width: 1010px) {
  .awssld__container {
    padding-bottom: 650px;
  }

  .awssld__content div h3 {
    font-size: 32px;
  }

  .awssld__content div h1 {
    font-size: 48px;
  }

  .awssld__content div p {
    font-size: 16px;
    padding: 0px 100px;
  }

  .sl-social {
    top: 30%;
  }
}

@media (max-width: 576px) {
  .awssld__container {
    padding-bottom: 667px;
  }

  .awssld__content div p {
    font-size: 16px;
    padding: 0px 50px;
  }

  .sl-social {
    top: 40%;
  }
}
